#works {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vh;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.worksTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;
}

.worksImgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;

}

.worksImg {
    object-fit: cover;
    height: 20rem;
    width: 15rem;
    margin: 0.5rem;
    border-radius: 10px;
}

.worksBtn {
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background: white;
    font-size: 1rem;
}

@media screen and (max-width:858px) {
    .worksImg {
        height: 48vw;
    }

}