.footer {
    width: 220vh;
    height: 4rem;
    background: rgb(40, 40, 40);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;

}